
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: "ConfirmDeviceCount",
})
export default class ConfirmDeviceCount extends Vue {
    private title: string = '确认设备数量';
    private formData: any = {
        deviceInfoList: [],
    };
    @Prop({
        type: String,
        default: '',
    }) private driveLicenseApplyId!: string; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: [],
    }) private deviceInfoList!: any; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: null,
    }) private userId!: any; // 归属客户id
    @Prop({
        type: Boolean,
        default: false,
    }) private showFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Watch('showFlag') // 监听父组件传过来的值
    private changeFlagFun(value: boolean) {
        if (value) {
            const deviceInfoList = JSON.parse(JSON.stringify(this.deviceInfoList));
            this.formData.deviceInfoList = deviceInfoList.filter((item: any) => (item.goodsId));
            if (this.formData.deviceInfoList.length > 0) {
                this.showDialog = true;
            } else {
                this.closeTodo(2);
            }
        }
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: any): any {
        this.showDialog = false;
        return num;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(2); // 给父组件传值
    }
    private validateNum(rule: any, value: any, callback: any) {
        const numVlid = /^(0|[1-9][0-9]*)$/;
        // const numVlid = /^[1-9][0-9]*$/;
        if (value === '' || value === null) {
          return callback(new Error('请输入设备数量'));
        } else if (!numVlid.test(value)) {
            return callback(new Error('设备数量格式不正确'));
        }
        callback();
    }
    // 生成订单
    private onSubmit(num: number) {
        const formData = this.$refs.formRef! as ElForm;
        formData.validate((valid) => {
            if (valid) {
                const selectProductList: any = [];
                this.formData.deviceInfoList.map((item: any) => {
                    if (item.goodsId && item.num > 0) {
                        const matchIndex = selectProductList.findIndex((prod: any) =>
                        (Number(prod.goodsId) === Number(item.goodsId)));
                        if (matchIndex >= 0) {
                            selectProductList[matchIndex].orderDetailNumber += item.num;
                        } else {
                            selectProductList.push({
                                goodsId: Number(item.goodsId), // 选中商品传给后端的值
                                appName: "",
                                orderDetailNumber: item.num, // 选中商品数量(累加)
                                orderDetailTimeNumber: 999,
                                orderGoodsSceneType: RESOURCE_CONFIG.DRIVE_GOODSSCENETYPE,
                                dbType: 0,
                            });
                        }
                    }
                });
                if (selectProductList.length <= 0) {
                    this.$alert(`当前无需要购买的设备`, '提示', {
                        showClose: false,
                        confirmButtonText: '确定',
                    });
                    return;
                }
                this.$router.push({
                    path: '/company/orderConfirm',
                    query: {
                        goodsData: JSON.stringify(selectProductList),
                        userData: this.userId,
                        driveLicenseApplyId: this.driveLicenseApplyId,
                    },
                }).catch((err) => err);

            } else {
                return false;
            }
        });
    }
}
