
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import ContentTitle from "@/components/ContentTitle.vue";
import { ElForm } from "element-ui/types/form";
import { debounce, verifyPhone } from "@/utils/utils";
import { TIME_FOREVER } from '@/utils/global';
import RegisterFileCoc from '../../../orderManage/list/component/RegisterFileDoc.vue';

@Component({
    name: "DeviceDriveListApply",
    components: {
        ContentTitle,
        RegisterFileCoc,
    },
})
export default class DeviceDriveListApply extends Vue {
    @Prop(Number) private applyType!: number; // 1为首次申请 2为重新申请
    @Prop({
        type: String,
        default: '',
    }) private orderId!: string; // 接收父组件传过来的值
    private userInfo: any = this.$store.state.userInfo;
    private contentTitle: string = '';
    private projectNameError: string = ""; // 项目名称已存在
    private proJectNamePlaceholder: string = '建议项目名称由“客户简称+项目说明”组成';
    private formData: any = {
        customerId: '',
        contractName: '',
        contractMobile: '',
        projectName: '',
        fileUrl: '',
        fileName: '',
        remark: '',
        reportCustomerId: null,
        companyId: null,    // 报备客户公司ID
        applyValidTimeForever: '',
        applyValidTime: '',
        registFileUrl: '',
        registFileName: '',
    };
    private formDataRule: any = {
        customerId: [
            { required: true, message: '请选择报备客户', trigger: 'change' },
        ],
        contractMobile: [
            { validator: this.validateMobile, trigger: 'blur' },
        ],
        projectName: [
            { required: true, message: '请输入项目名称', trigger: 'change' },
        ],
        fileUrl: [
            { required: true, message: '请上传设备清单', trigger: 'change' },
        ],
        remark: [
            { required: true, message: '请输入备注说明', trigger: 'change' },
        ],
        registFileUrl: [
            { required: true, message: '请上传注册文件', trigger: 'change' },
        ],
    };
    private customerOptions: any[] = [];
    private personInfo: any = {}; // 当前登录人信息
    private isEcoPartner: any = false;
    private deviceInfoList = [];
    private loading: boolean = false;
    private RegisterFileFlag: boolean = false;
    private applyForeverChange(val: any) {
        if (val === 1) {
            this.formData.applyValidTime = '';
        }
        const formRef = this.$refs.deviceDriveListApplyForm! as ElForm;
        formRef.clearValidate('applyValidTime');
    }
    @Watch("applyType", { immediate: true })
    private applyTypeWatch(val: number) {
        if (val === 1) {
            this.contentTitle = "获取设备驱动匹配清单";
        } else if (val === 2) {
            this.contentTitle = "设备驱动匹配清单申请";
        }
    }
    @Emit('goBack')
    private backTodo(num: number): number {
        // num === 1 返回
        // num === 2 添加成功返回
        // num === 3 跳转报备客户页面
        return num;
    }
    @Emit('payBack')
    private payBackTodo(obj: any): any {
        // obj 支付订单内容
        return obj;
    }
    private getUserCustomerCompany() {
        this.$httpService.getData({
                notLoading: true,
            }, `/apiProxy/api/frontend/company/customer/user/customer-company`)
        .then((res: any) => {
            this.customerOptions = res;
        });
    }
    // 获得申请授权信息
    private getApplyInfo() {
        if (this.orderId === '') {
            return;
        }
        this.$httpService.getData({id: this.orderId},
        `/apiProxy/api/frontend/drive/license/detail`)
        .then((res: any) => {
            // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (4, "已终止")
            this.formData = {
                customerId: '',
                contractName: res.contractName,
                contractMobile: res.contractMobile,
                projectName: res.projectName,
                fileUrl: res.deviceFileUrl,
                fileName: res.deviceFileName,
                remark: res.remark,
                reportCustomerId: null,
                companyId: res.companyId,    // 报备客户公司ID
                reportName: res.reportName,
                registFileUrl: '',
                registFileName: '',
                applyValidTimeForever: 0,
                applyValidTime: '',
            };
            // 20240705 new add start
            this.formData.registFileUrl = res.registFileUrl || '';
            this.formData.registFileName = res.registFileName || '';
            this.formData.applyValidTimeForever = 0;
            this.formData.applyValidTime = res.applyValidTime ? res.applyValidTime.substr(0, 10) : '';
            if (res.applyValidTime === TIME_FOREVER) {
                this.formData.applyValidTimeForever = 1;
                this.formData.applyValidTime = '';
            }
            // 20240705 new add end
            this.deviceInfoList = res.deviceGoodsDetailList;
            if (this.userInfo.partnerStatus !== 3 && this.userInfo.partnerStatus !== 4) {
                this.isEcoPartner = true;
                // if (this.userInfo.nickname === res.companyName) {
                //     this.formData.customerId = res.companyId;
                // } else {
                //     this.formData.customerId = res.customerId;
                // }
                if (res.companyId) {
                    this.formData.customerId = res.companyId;
                } else {
                    this.formData.customerId = res.customerId;
                }
                // this.customerChange(this.formData.customerId, true);
            } else {
                this.isEcoPartner = false;
            }
            console.log("this.formData", this.formData);
        });
    }
    // 通过id获取到报备客户信息
    private customerChange(vId: any, infuseFlag = false) {
        if (vId) {
            const customerOwnArr = this.customerOptions.filter((item: any) => item.id === vId);
            let ownFlag = false;
            if (customerOwnArr.length > 0 && customerOwnArr[0].info === 1) {
                ownFlag = true;
            }
            this.getPersonInfo(ownFlag, vId, infuseFlag);
        }
    }
    private mounted() {
        this.$nextTick(async () => {
            // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (4, "已终止")
            if (this.userInfo.partnerStatus !== 3 && this.userInfo.partnerStatus !== 4) {
                this.isEcoPartner = true;
                this.getUserCustomerCompany();
            } else {
                this.isEcoPartner = false;
                // 添加参数，防止接口响应过慢 和 getApplyInfo 造成冲突
                this.getPersonInfo(true, null, true);
            }
            await this.getApplyInfo();
        });
    }
    private getPersonInfo(ownFlag = true, projectCustomerId = null, infuseFlag = false) {
        const paramData: any = {};
        if (ownFlag) {
            paramData.type = 1;
        } else {
            paramData.type = 0;
        }
        if (projectCustomerId) {
            paramData.projectCustomerId = projectCustomerId;
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/company/customer/info')
        .then((rep: any) => {
            if (rep) {
                if (ownFlag) {
                    if (!this.isEcoPartner) {
                        this.customerOptions = [];
                        this.customerOptions.push({
                            id: rep.customer.customerCompanyId,
                            name: this.userInfo.nickname,
                        });
                    }
                    this.formData.customerId = rep.customer.customerCompanyId;
                }
                if (projectCustomerId !== rep.customer.customerCompanyId) {
                    this.formData.reportCustomerId = rep.customer.customerId;
                } else {
                    this.formData.reportCustomerId = null;
                }
                this.formData.companyId = rep.customer.customerCompanyId;
                this.formData.contractName = rep.customer.customerInfoContacts;
                this.formData.contractMobile = rep.customer.customerInfoMobile;
            }
        });
    }
    private onSubmit(formName: any) {
        this.projectNameError = '';
        const formRef = this.$refs[formName] as ElForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                let path = `/apiProxy/api/frontend/drive/license/apply`;
                const paramData = Object.assign({}, this.formData);
                paramData.applyValidTime =
                    paramData.applyValidTimeForever === 1
                    ? TIME_FOREVER
                    : paramData.applyValidTime + ' 00:00:00';
                delete paramData.applyValidTimeForever;
                paramData.deviceInfoList = this.deviceInfoList;
                if (this.applyType === 2) {
                    path = `/apiProxy/api/frontend/drive/license/re-apply`;
                    paramData.id = this.orderId;
                }
                this.$httpService.postData(paramData, path)
                .then((rep: any) => {
                    this.$alert(`申请成功`, '提示', {
                        showClose: false,
                        confirmButtonText: '确定',
                        callback: (action) => {
                            this.backTodo(2);
                        },
                    });
                }).catch((err: any) => {
                    if (err.code === 1008500016) {
                        this.projectNameError = '项目名称已存在';
                    }
                });
            }
        });
    }
    private cancelHandle() {
        this.backTodo(1);
    }
    private customerHandle() {
        this.backTodo(3);
    }
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && !verifyPhone(value)) {
          return callback(new Error('手机号格式不正确!'));
        }
        callback();
    }
    private beforeFileUpload(file: any) {
        // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
        return new Promise(async (resolve: any, reject: any) => {
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 10MB!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
        });
    }
    private httpRequestFileBusiness(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/drive/license/parse', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.formData.fileName = data.file.name;
            this.formData.fileUrl = res.fileUrl;
            this.deviceInfoList = res.deviceInfoList;
            const formRef: any = this.$refs.deviceDriveListApplyForm;
            formRef.validateField('fileUrl');
        });
    }
    private beforeRegisterFileUpload(file: any) {
        return new Promise(async (resolve: any, reject: any) => {
            const fileType = file.type;
            const fileName = file.name;
            // 根据后缀判断文件类型
            let fileSuffix = "";
            // 前缀名不能包括 括号
            let filePrefix = "";
            try {
                const fileArr = fileName.split(".");
                fileSuffix = fileArr[fileArr.length - 1];
                filePrefix = fileArr.slice(0, fileArr.length - 1).join('.');
            } catch (err) {
                fileSuffix = "";
                filePrefix = "";
            }
            if (fileSuffix !== 'mf') {
                this.$message.warning('上传文件格式错误!');
                return reject(false);  // 注意这里需要用reject来返回错误的信息,防止图片自动上传
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 10MB!');
                return reject(false);
            }
            resolve(file);
        });
    }
    private httpRequestRegisterFile(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.formData.registFileName = data.file.name;
            this.formData.registFileUrl = res.path;
            const formRef: any = this.$refs.deviceDriveListApplyForm;
            formRef.validateField('registFileUrl');
        });
    }
    // WisIOT平台注册文件获取方法
    private handleRegisterFile(flag: boolean) {
        this.RegisterFileFlag = flag;
    }
}
