
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import ContentTitle from "@/components/ContentTitle.vue";
import ConfirmDeviceCount from "./ConfirmDeviceCount.vue";
import { TIME_FOREVER } from '@/utils/global';

@Component({
    name: "DeviceDriveListApply",
    components: {
        ContentTitle,
        ConfirmDeviceCount,
    },
})
export default class DeviceDriveListApply extends Vue {
    @Prop({
        type: String,
        default: '',
    }) private orderId!: string; // 接收父组件传过来的值
    private baseUrlIframe = process.env.VUE_APP_URL;
    private contentTitle: string = '设备驱动匹配清单详情';
    private projectNameError: string = ""; // 项目名称已存在，如需为该项目申请延续、服务器变更等操作，请返回项目列表查找到该项目后点击对应的操作功能申请
    private formData: any = {
        // authType: 1, // 1：mc 2:wis3d
        // customerId: '',
        // contacts: '',
        // mobile: '',
        // projectName: '',
        // licenseEndTime: '',
        // goodsId: '4',
        // registerUrl: '',
        // userNumber: 0,
        // remarks: '',
        // actuallyMoney: '',
        // type: null,
        // time: '1',
        // reportCustomerId: null,
        // projectCustomerId: null,
        // orderNo: '',
        // associatedOrderId: '',
        // productName: '',
    };
    private fileName: any = '';
    private deviceInfoList = [];
    private loading: boolean = false;
    private showFlag: boolean = false;
    private timeFormatter(time: any) {
        if (time) {
            if (time === TIME_FOREVER) {
                return '永久';
            } else {
                return time.substr(0, 10);
            }
        } else {
            return '-';
        }
    }
    @Emit('goBack')
    private backTodo(num: number): number {
        // num === 1 返回
        // num === 2 跳转授权历史页面
        // num === 3 跳转报备客户页面
        return num;
    }
    @Emit('payBack')
    private payBackTodo(obj: any): any {
        // obj 支付订单内容
        return obj;
    }
    // 获得申请授权信息
    private getApplyInfo() {
        if (this.orderId === '') {
            return;
        }
        this.$httpService.getData({id: this.orderId},
        `/apiProxy/api/frontend/drive/license/detail`)
        .then((res: any) => {
            this.formData = res;
            this.deviceInfoList = res.deviceGoodsDetailList;
        });
    }
    private mounted() {
        this.$nextTick(async () => {
            await this.getApplyInfo();
        });
    }
    private onPay() {
        this.showFlag = true;
    }
    private closeDialog(num: number) {
        this.showFlag = false;
    }
    private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
        if (!row.goodsId) {
            if (columnIndex === 5) {
                return {
                    rowspan: 1,
                    colspan: 4,
                };
            } else if (columnIndex === 6 || columnIndex === 7) {
                return {
                    rowspan: 0,
                    colspan: 0,
                };
            }
        }
    }
    private async downloadClick(row: any) {
        if (!this.formData.deviceFileUrl || this.formData.deviceFileUrl === '') {
            this.$alert(`设备清单未上传`, '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        const deviceFileUrl = this.formData.deviceFileUrl;
        const response = await fetch(this.baseUrlIframe + deviceFileUrl);
        const blob = await response.blob();
        this.triggerADownload(URL.createObjectURL(blob), row);
    }
    private async downloadRegistFileUrlClick(row: any) {
        if (!this.formData.registFileUrl || this.formData.registFileUrl === '') {
            this.$alert(`注册文件未上传`, '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        const deviceFileUrl = this.formData.registFileUrl;
        const response = await fetch(this.baseUrlIframe + deviceFileUrl);
        const blob = await response.blob();
        this.triggerADownload(URL.createObjectURL(blob), row);
    }
    private triggerADownload(blob: any, row: any) {
        const a = document.createElement('a');
        const fileName = this.formData.deviceFileName;
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
    private gotoPay() {
        this.$router.push({ path: "/company/myOrder", query: {orderStatus: '0'}});
    }
}
