
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import AuthTips from "@/components/AuthTips.vue";
import DeviceDriveListApply from "./component/DeviceDriveListApply.vue";
import DeviceDriveDetail from "./component/DeviceDriveDetail.vue";
import { copyGetParam } from '@/utils/utils';

@Component({
    name: "DeviceDriveBuy",
    components: {
        ContentTitle,
        AuthTips,
        DeviceDriveListApply,
        DeviceDriveDetail,
    },
})
export default class DeviceDriveBuy extends Vue {
    private loading: boolean = false;
    private height: any = 'calc(100vh - 280px)';
    private activePage: number = 1; // 1、主页面；2、添加报备客户；3、报备用户详情；
    private applyType: number = 1;
    // AuthTips
    private tipsIndex: number = 0; // 0，不显示；1，认证提示；2，切换账号提示
    private searchParam: any = {
        pageSize: 10,
        pageNo: 1,
        name: '',
        mobile: '',
        statusList: '',
        followRecord: '',
    };
    private total: number = 0;
    private tableData = [];
    private currentRow: any = {};
    private gotoType: string = '';
    private created() {
        if (this.$route.fullPath.indexOf('type=') > -1) {
            this.gotoType = this.$route.query.type + '';
            if (this.gotoType === 'project' || this.gotoType === 'authorize' || this.gotoType === 'ecology') {
                this.activePage = 2;
            }
        }
        this.$nextTick( () => {
            this.getData();
        });
    }
    private mounted() {
        this.height = window.innerHeight - 295;
        window.onresize = () => {
            return (() => {
                this.height = window.innerHeight - 295;
            })();
        };
    }
    private getData() {
        this.loading = true;
        this.tableData = [];
        let paramData: any = {};
        paramData = copyGetParam(this.searchParam);
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/drive/license/list').then((res: any) => {
            this.tableData = res.list;
            this.total = res.total;
            this.loading = false;
        });
    }
    private onSearch() {
        this.total = 0;
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = 10;
        this.getData();
    }
    private handleSizeChange(val: number) {
        this.searchParam.pageSize = val;
        this.getData();
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getData();
    }
    private detailClick(row: any) {
        this.currentRow = row;
        this.activePage = 3;
    }
    private addApply(row: any, applyType: number) {
        const useInfo = this.$store.state.userInfo;
        if (useInfo.userType === 2 && useInfo.certStatus !== 2) {
            this.tipsIndex = 1;
            return;
        }
        this.currentRow = row;
        this.applyType = applyType;
        this.activePage = 2;
    }
    private receiveCloseAuthTips() {
        this.tipsIndex = 0;
    }
    private propBack(num: number) {
        this.activePage = 1;
        if (num === 2) {
            // 添加成功
            this.getData();
        }
        if (num === 3) {
            // 跳转报备客户页面
            this.$router.push("/company/reportCustomer?type=deviceDriveBuy").catch((err) => err);
        }
    }
    private downloadAuth(url: any) {
        this.$message.success('文件下载中，请稍等');
        window.open(url);
    }
}
