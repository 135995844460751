export const RESOURCE_CONFIG = {
    SERVER_GOODSID: 1, // 云服务器goodsId
    SERVER_GOODSSCENETYPE: 1, // 服务器 orderGoodsSceneType
    SERVER_GOODSSCENETYPE_RENEW: 2, // 服务器续费 orderGoodsSceneType
    SERVER_GOODSSCENETYPE_UPGRADE: 3, // 服务器升级 orderGoodsSceneType
    USERNUMBER_GOODSID: 19, // 云用户数 goodsId
    USERNUMBER_GOODSSCENETYPE: 10, // 云用户数 orderGoodsSceneType
    ORACLE_GOODSID: 3, // oracle goodsId
    ORACLE_GOODSSCENETYPE: 8, // oracle orderGoodsSceneType
    ORACLE_GOODSSCENETYPE_RENEW: 5, // oracle续费 orderGoodsSceneType
    ORACLE_GOODSSCENETYPE_UPGRADE: 6, // oracle升级 orderGoodsSceneType
    APP_GOODSPRICETYPE: 6, // 应用 goodsPriceType
    APP_GOODSSCENETYPE: 9, // 应用 orderGoodsSceneType
    APP_GOODSSCENETYPE_RENEW: 19, // 应用续费 orderGoodsSceneType
    APP_GOODSSCENETYPE_TRY: 15, // 应用试用购买 orderGoodsSceneType
    SAAS_N2LABELPRINT_GOODSID: 889, // N2Cloud标签打印协同系统goodsId——(线上id: 889)
    SAAS_GOODSPRICETYPE: 7, // SaaS goodsPriceType
    SAAS_GOODSSCENETYPE: 20, // SaaS首次 orderGoodsSceneType
    SAAS_GOODSSCENETYPE_RENEW: 21, // SaaS续费 orderGoodsSceneType
    SERVICE_GOODSPRICETYPE: 12, // 服务 goodsPriceType
    SERVICE_GOODSSCENETYPE: 24, // 服务 orderGoodsSceneType
    DRIVE_GOODSPRICETYPE: 15, // 驱动 goodsPriceType
    DRIVE_GOODSSCENETYPE: 25, // 驱动 orderGoodsSceneType
    GRANT_GOODSID: 4, // 授权用户数 goodsId
    GRANT_GOODSPRICETYPE: 13, // 授权用户数 goodsPriceType
    MOREDEV_GOODSID: 8, // mc4.0开发平台 goodsId
    MOREDEV_SKUID: 3, // mc4.0开发平台 生产环境的 sukId
};
// 3.0配置
// 初始 2vCup 3G 30G
// 后续每个应用需要增加1vCPU/1.5GB内存/15GB硬盘
export const SERVER_CONFIG = {
    SERVER_TYPE: 1, // 3.0 正式服务器
    SERVER_TYPE_TRIAL: 3, // 3.0 试用服务器
    TWO_VCUP: 2, // 2核
    TWO_VCUP_MEMORY: 3,
    TWO_VCUP_HARDDISK: 30,
    FOUR_VCUP: 4, // 4核
    FOUR_VCUP_MEMORY: 6,
    FOUR_VCUP_HARDDISK: 60,
    SIX_VCUP: 6, // 6核
    SIX_VCUP_MEMORY: 10,
    SIX_VCUP_HARDDISK: 100,
    EIGHT_VCUP: 8, // 8核
    EIGHT_VCUP_MEMORY: 16,
    EIGHT_VCUP_HARDDISK: 150,
    TEN_VCUP: 10, // 10核
    TEN_VCUP_MEMORY: 16,
    TEN_VCUP_HARDDISK: 150,
    TWELVE_VCUP: 12, // 12核
    TWELVE_VCUP_MEMORY: 32,
    TWELVE_VCUP_HARDDISK: 200,
};
// 4.0配置
// 初始需要包括 开发平台默认算2个 4vCup 16G 60G
// 后续每个应用需要增加1核/4G/10G
export const SERVER_CONFIG_MC_FOUR = {
    SERVER_TYPE: 4, // 4.0 正式服务器
    SERVER_TYPE_TRIAL: 5, // 4.0 试用服务器
    FOUR_VCUP: 4, // 4核
    FOUR_VCUP_MEMORY: 16,
    FOUR_VCUP_HARDDISK: 60,
    SIX_VCUP: 6, // 6核
    SIX_VCUP_MEMORY: 32,
    SIX_VCUP_HARDDISK: 100,
    EIGHT_VCUP: 8, // 8核
    EIGHT_VCUP_MEMORY: 32,
    EIGHT_VCUP_HARDDISK: 100,
    OTHER_VCUP: 10, // 其它
    OTHER_VCUP_MEMORY: 32,
    OTHER_VCUP_HARDDISK: 200,
};
// 记录数据库类型，有时候使用时，直接使用常量
export const DB_TYPE = {
    PG: 1,
    ORACLE: 2,
};
// 记录一下
// 服务器goods模板
const serverGoods: any = {
    goodsId: 1, // 选中商品传给后端的值
    appName: '云服务器',
    orderDetailNumber: 1, // 选中商品数量(固定不变的)
    orderDetailTimeNumber: 0, // 时长
    cpu: 0,
    memory: 0,
    harddisk: 0,
    orderGoodsSceneType: 1,
    dbType: 0,
};
const userNumberGoods: any = {
    goodsId: 19, // 选中商品传给后端的值
    appName: '云用户数',
    orderDetailNumber: 1, // 选中商品数量(固定不变的)
    orderDetailTimeNumber: 999, // 时长
    orderGoodsSceneType: 10,
    userNumber: 0,
};
// Oracle goods模板
const oracleGoods: any = {
    goodsId: 3, // 选中商品传给后端的值
    appName: 'Oracle',
    orderDetailNumber: 1, // 选中商品数量(累加)
    orderDetailTimeNumber: 0, // 时长
    cpu: 0,
    memory: 0,
    harddisk: 0,
    orderGoodsSceneType: 8,
};
// 应用goods模板
const appGoods: any = {
    goodsId: 0, // 选中商品传给后端的值
    orderDetailNumber: 1, // 选中商品数量(固定不变的)
    orderDetailTimeNumber: 1, // 时长
    orderGoodsSceneType: 9,
    suiteName: 'mc',
};
// SaaS goods模板
const saasGoods: any = {
    goodsId: 0, // 选中商品传给后端的值
    orderDetailNumber: 1, // 选中商品数量(固定不变的)
    orderDetailTimeNumber: 1, // 时长
    orderGoodsSceneType: 20, // 20首次购买，21续费
};
// 服务 goods模板
const serviceGoods: any = {
    appName: '',
    goodsId: 0, // 选中商品传给后端的值
    orderDetailNumber: 1, // 选中商品数量(固定不变的)
    orderDetailTimeNumber: 1, // 时长
    orderGoodsSceneType: 24,
};

export const TIME_FOREVER = '3000-01-01 00:00:00';  // 永久时间默认为：3000-01-01 00:00:00
