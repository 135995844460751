
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';

@Component({
    name: "RegisterFileCoc",
})
export default class RegisterFileCoc extends Vue {
    private baseUrl = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private title: string = 'WisIOT平台注册文件获取方法';
    @Prop({
        type: Boolean,
        default: false,
    }) private showFlag!: boolean; // 接收父组件传过来的值
    private list: any = [{
        title: '1、登录需要授权的WisIOT平台',
        img: require('@/assets/images/WisIOT/img1.png'),
    }, {
        title: '2、单击“授权申请”',
        img: require('@/assets/images/WisIOT/img2.png'),
    }, {
        title: '3、填写授权申请内容后单击“生成”',
        img: require('@/assets/images/WisIOT/img3.png'),
    }, {
        title: '4、下载生成的注册文件',
        img: require('@/assets/images/WisIOT/img4.png'),
    }];
    private imgList = [];
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo() {
        return false;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(); // 给父组件传值
    }
    private mounted() {
        this.imgList = this.list.map((i: any) => i.img);
    }
}
